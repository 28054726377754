import joinMovementSeedImg from "@/assets/images/marketplace/join-the-movement-seed.photo.webp";
import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import { checkUserHasArtistRole } from "@/components/common/utiles";
import { getCookie } from "@/config/helpers/api_helper";
import { createSelectorMyProfileData } from "@/config/helpers/createReduxSelectors";
import { getMyProfile, resetMyProfile } from "@/store/actions";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const UseMetaMask = dynamic(() => import("@/config/helpers/web3Logic"), {
  ssr: false,
});

function ShowcaseNftMarketplaceSection() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [storageItem, setStorageItem] = useState(null);
  const [isAuthUser, setIsAuthUser] = useState(false);

  const { error, success, MyProfileUser, message, loading } = useSelector(
    createSelectorMyProfileData
  );

  useEffect(() => {
    // Check if running on client side
    if (typeof window !== "undefined" && getCookie("authentication") !== "") {
      const item = JSON.parse(getCookie("authentication"));
      setStorageItem(item);
      setIsAuthUser(!!getCookie("authentication"));
    }
  }, [isAuthUser]);

  useEffect(() => {
    if (storageItem && isEmpty(MyProfileUser)) {
      setTimeout(() => {
        dispatch(getMyProfile(storageItem.UserName));
      }, 300);
    }
  }, [storageItem, MyProfileUser, userData, dispatch]);

  useEffect(() => {
    setUserData(MyProfileUser?.User);
  }, [MyProfileUser, userData]);

  return (
    <>
      <div className="center">
        <div className="marketplace__center home__ShowCase_center">
          <div className="home__ShowCase__join">
            <div className="home__ShowCase__stage">
              <BurstPuckerImageTitles width={18} height={18} />
              NFT marketplace
            </div>

            <div className="home__ShowCase__title">
              Buy, sell, and showcase NFTs
            </div>
            <div className="home__ShowCase__description home__ShowCase__description-color">
              {/* Seed is the first and largest NFT marketplace for photographers.
              Create, buy, sell, trade and enjoy the #seed NFT community. */}
              SEED.Photo is the first and most exclusive NFT marketplace for
              photographers. Create, buy, sell, trade and engage the #SEED.Photo
              NFT community.
            </div>

            <div className="description__join_btns home__ShowCase__btns">
              <div>
                {typeof window !== "undefined" &&
                getCookie("authentication") !== "" &&
                !!getCookie("authentication") &&
                userData &&
                !isEmpty(MyProfileUser) ? (
                  checkUserHasArtistRole(MyProfileUser?.User) ? (
                    <Link
                      className="button home__ShowCase__margin2 "
                      href="/nfts/upload"
                    >
                      Upload
                    </Link>
                  ) : (
                    <>
                      <Link
                        className="button home__ShowCase__margin "
                        href="/account/verification-form"
                        onClick={() => {
                          dispatch(resetMyProfile());
                        }}
                      >
                        Join Now
                      </Link>
                    </>
                  )
                ) : (
                  <UseMetaMask
                    userData={userData}
                    isHeader={true}
                    textButton={"Connect"}
                    customeClass="button header__button header__connect"
                  />
                )}
              </div>
              <Link
                href="./nfts"
                className="description__join_btn home__ShowCase__padding
                home__ShowCase__margin"
              >
                Explore
              </Link>
            </div>
          </div>
          <div className="home__ShowCase__gallery">
            <Image
              style={{ objectFit: "contain" }}
              width={300}
              height={230}
              src={joinMovementSeedImg}
              loading="lazy"
              alt="Join The Movement seed.photo"
              quality={90}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowcaseNftMarketplaceSection;
